@import "../../Common";

.actions {
  > .section:not(:first-child) {
    > .content > .left-side > .title p {
      display: none;
    }
  }

  > .section {
    > .content {
      > .image {
        width: 100%;
      }

      > .left-side {
        > .image {
          max-width: 100%;
        }
      }

      > .right-side {
        position: relative;

        > .sub_title {
          @include medium-bold();
        }

        > .title {
          @include large-bold();
          margin-bottom: 1em;
        }
      }

      > .circle1 {
        border-radius: 50%;
        position: absolute;
        width: 16em;
        height: 16em;
        background: #FFA800;
        left: -27em;
        top: 3em;

        @media (max-width: 1024px) {
          display: none;
        }
      }

      > .circle2 {
        border-radius: 50%;
        position: absolute;
        width: 8em;
        height: 8em;
        right: -14em;
        top: 40em;
        background: #FF37C3;

        @media (max-width: 1024px) {
          display: none;
        }
      }

      > .circle3 {
        border-radius: 50%;
        position: absolute;
        width: 8em;
        height: 8em;
        left: 2em;
        top: 105em;
        background: #018FA3;
        z-index: 1000;

        @media (max-width: 1024px) {
          display: none;
        }
      }

      > .circle4 {
        border-radius: 50%;
        position: absolute;
        width: 10.5em;
        height: 10.5em;
        left: -5em;
        top: 170em;
        background: #FF37C3;
        z-index: 1000;

        @media (max-width: 1024px) {
          display: none;
        }
      }
    }
  }
}