@import "../../Common";

.project {
  > .section:not(:first-child) {
    > .content > .left-side > .title p {
      display: none;
    }
  }

  > .section {
    > .content {
      > .image {
        width: 100%;
      }

      > .circle1 {
        border-radius: 50%;
        position: absolute;
        width: 7.75em;
        height: 7.75em;
        right: -14em;
        top: 20em;
        background: #006CFF;
      }

      > .circle2 {
        border-radius: 50%;
        position: absolute;
        width: 16em;
        height: 16em;
        left: -18em;
        top: 25em;
        background: #0B9D3A;
      }

      > .circle3 {
        border-radius: 50%;
        position: absolute;
        width: 7.75em;
        height: 7.75em;
        left: 0em;
        top: 45em;
        background: #0BE495;

        @media (max-width: 1024px) {
          display: none;
        }
      }
    }
  }
}