@import "../../Common";

.objectives {
  > .section:not(:first-child) {
    > .content > .left-side > .title p {
      display: none;
    }
  }

  > .section {
    margin: 0em auto 1em auto !important;

    @media (max-width: 1024px) {
      margin: 0em 1em 1em 1em !important;
    }

    > .content {
      > .image {
        width: 100%;
      }

      > .right-side {
        position: relative;

        > .circle {
          border-radius: 50%;
          position: absolute;
          width: 1em;
          height: 1em;
          left: -2em;
          top: 0.2em;
          background: #0BE495;
        }
      }
    }
  }
}