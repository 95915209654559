@import "../../Common";

.download {
  > .section:last-child {
    > .content > .divider {
      display: none;
    }
  }

  > .section {
    margin-bottom: 1em !important;

    > .content {
      > .left-side {
        > .image {
          display: flex;
          justify-content: flex-end;

          @media (max-width: 1024px) {
            justify-content: center;
          }

          > img {
            max-width: 6em;

            @media (max-width: 1024px) {
              max-width: 30%;
            }
          }
        }
      }

      > .right-side {
        margin-bottom: 3em !important;
        position: relative;

        @media (max-width: 1024px) {
          text-align: center !important;
        }

        > .title {
          @include medium-bold();
        }

        > .subTitle {
          @include small-regular();
          color: #FF9900;
        }

        > .detail {
          @include x-small-regular();
          margin-bottom: 0;
        }

        > .download {
          @include small-regular();
          color: #FF9900;
        }
      }
    }
  }
}