@import "../Common";

.footer {
  > .subscriptionForm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;

    @media (max-width: 1024px) {
      display: none;
    }

    > .rectangle {
      width: 100%;
      height: 1px;
      background: $neutral-200;
      flex: none;
      order: 0;
      align-self: stretch;
      flex-grow: 0;
    }

    > .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 4em 5em;
      gap: 2em;
      flex: none;
      order: 1;
      align-self: stretch;
      flex-grow: 0;

      > .title {
        @include x-large-bold();
        text-align: center;
        color: $neutral-700;
        flex: none;
        order: 0;
        flex-grow: 0;
      }

      > .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0px;
        gap: 1em;
        flex: none;
        order: 1;
        flex-grow: 0;

        > .input {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: flex-start;
          padding: 0px;
          gap: 0.75em;
          flex: none;
          order: 0;
          flex-grow: 0;

          > .input {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            padding: 0px;
            gap: 0.25em;
            flex: none;
            order: 0;
            flex-grow: 1;

            > .input {
              box-sizing: border-box;
              display: flex;
              flex-direction: row;
              align-items: center;
              padding: 1em;
              gap: 0.5em;
              background: $neutral-0;
              border: 1px solid $neutral-300;
              border-radius: 0.5em;
              flex: none;
              order: 0;
              align-self: stretch;
              flex-grow: 0;

              > input {
                @include labels-regular();
                display: flex;
                align-items: center;
                color: $neutral-500;
                flex: none;
                order: 0;
                flex-grow: 0;
                width: 100%;
              }
            }
          }

          > .buttons {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 1em;
            gap: 0.5em;
            background: $accent-base;
            border-radius: 0.5em;
            flex: none;
            order: 1;
            flex-grow: 0;

            > p {
              @include labels-bold();
              display: flex;
              align-items: center;
              color: $neutral-0;
              flex: none;
              order: 0;
              flex-grow: 0;
            }
          }
        }

        > .terms {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0px;
          gap: 0.5em;
          flex: none;
          order: 1;
          align-self: stretch;
          flex-grow: 0;
        }
      }
    }
  }

  .footer {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;

    > .main {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 5em 10em;
      gap: 0.75em;
      background: #1F1F1F;
      flex: none;
      order: 0;
      align-self: stretch;
      flex-grow: 0;

      @media (max-width: 1024px) {
        padding: 1em;
      }

      > .container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 1em;
        gap: 2em;
        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;

        > .boilerPlate {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 0px;
          gap: 3em;
          flex: none;
          order: 0;
          flex-grow: 1;
          align-self: stretch;

          @media (max-width: 1024px) {
            gap: 1em;
          }

          > .upper {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 0px;
            gap: 1em;
            flex: none;
            order: 0;
            align-self: stretch;
            flex-grow: 0;
            width: 100%;
            justify-content: space-between;

            @media (max-width: 1024px) {
              flex-direction: column;
              align-items: flex-start;
            }

            > .right-side {
              display: flex;
              flex-direction: column;
              gap: 0.5em;
              align-items: flex-end;
              width: 100%;

              > .socialMedia {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                padding: 0px;
                gap: 1em;
                flex: none;
                order: 0;
                flex-grow: 1;

                @media (max-width: 1024px) {
                  align-items: flex-start;
                }

                > div {
                  display: flex;
                  flex-direction: row;
                  align-items: flex-start;
                  padding: 0px;
                  gap: 1em;
                  flex: none;
                  order: 0;
                  flex-grow: 0;

                  img {
                    flex: none;
                    order: 1;
                    flex-grow: 0;
                    width: 2em;
                    height: 2em;
                  }
                }
              }

              > .contact-us {
                @include x-small-medium();
                color: $neutral-0;
              }
            }
          }
        }

        > .boilerPlateContainer {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          padding: 0px;
          gap: 3em;
          flex: none;
          order: 1;
          align-self: stretch;
          flex-grow: 0;

          @media (max-width: 1024px) {
            flex-direction: column;
          }

          > div {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0px;
            gap: 1em;
            flex: none;
            order: 0;
            align-self: stretch;
            flex-grow: 0;
            @include x-small-medium();
            color: $neutral-0;
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            flex: 1 1;

            > p {
              text-align: justify;
            }

            > img {
              align-self: baseline;
            }
          }
        }
      }

      > p {
        @include x-small-medium();
        color: $neutral-0;
        flex: none;
        order: 1;
        flex-grow: 0;
      }
    }

    > .disclaimer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 2em 10em;
      gap: 1em;
      background: #000000;
      flex: none;
      order: 1;
      align-self: stretch;
      flex-grow: 0;

      @media (max-width: 1024px) {
        padding: 1em;
      }

      > .container {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px;
        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;

        @media (max-width: 1024px) {
          align-self: center;
        }

        > .footerLinks {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          padding: 0px;
          gap: 1em;
          flex: none;
          order: 0;
          flex-grow: 1;

          > p {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 0.875em;
            line-height: 160%;
            text-decoration-line: underline;
            color: $neutral-0;
            flex: none;
            order: 0;
            flex-grow: 0;
          }
        }
      }
    }
  }
}