@import "../Common";

body {
  .carousel {
    position: relative;
    align-self: stretch;

    .carousel-item {
      text-align: justify;
    }

    .carousel-root .carousel:not(.carousel-slider) {
      display: none;
    }

    .carousel-status {
      display: none;
    }

    .carousel-component {
      > .item {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 1.5em 2em;
        gap: 0.5em;

        position: absolute;
        left: 10%;
        right: 40%;
        bottom: 0%;

        @media (max-width: 1024px) {
          position: relative;
          left: 0%;
          right: 0%;
          bottom: 0%;
        }

        background: #FFFFFF;
        border-radius: 1.5em 1.5em 0 0;
      }
    }

    .control-dots {
      width: calc(12.56% - 1em);
      margin-top: 8em;
      padding: 0.5em;
      display: flex;
      align-items: flex-start;
      bottom: 0.5em;
      right: 2em;
      justify-content: flex-end;

      @media (max-width: 1500px) {
        width: calc(25% - 1.5em);
      }

      @media (max-width: 1024px) {
        display: none;
      }
    }

    .control-dots .dot {
      width: 1.5em;
      height: 1.5em;
      background-color: rgb(255, 255, 255);
      border-radius: 50%;
      opacity: 0.5;
    }

    .control-dots .dot.selected {
      width: 1.5em;
      height: 1.5em;
      background-color: rgb(255, 153, 0);
      margin-right: 0.5em;
      border-radius: 50%;
    }

    .slider {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 0;

      > .slide {
        flex-direction: column;
        align-items: flex-start;
        padding: 0;
        gap: 1.5em;
        flex: none;
        order: 0;
        flex-grow: 1;
        width: 100%;

        > img {
          flex: none;
          order: 0;
          align-self: stretch;
          flex-grow: 0;
        }
      }
    }

    .slider-wrapper {
      @media (max-width: 1024px) {
        overflow: visible;
      }
    }
  }

  .carousel-item {
    color: $black;
    flex: 1;
    @include large-medium;

    @media (max-width: 1024px) {
      @include small-bold();
    }
  }
}

.carousel.carousel-slider {
  @media (max-width: 1024px) {
    overflow: visible !important;
  }
}
