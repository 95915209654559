@import "../components/Common";

.fileUploader {
  .MuiPaper-root {
    > .css-bhufu8-MuiTypography-root, > .css-r52jkk-MuiTypography-root {
      display: none;
    }
  }
}

.fileUploader .image {
  display: flex;
  flex-direction: column;

  .down {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  img.delete {
    width: 18px;
    height: 18px;
    padding: 0.5em;
  }
}
