@import "colors";
@import "typography";

body {
  margin: 0;
}

.clickable {
  border: 1px solid $white;
  padding: 1.5em;
}

.header .clickable {
  padding: 0em;
}

.clickable.anchor {
  border: none;
  padding: 0 0.2em;
  display: inline-block;
  text-decoration: underline;
}

.clickable:focus, .clickable:hover {
  cursor: pointer;
  background: $neutral-50;
  border: 1px solid $neutral-500;
  border-radius: 0.5em;
}

.clickable.anchor:focus, .clickable.anchor:hover {
  cursor: pointer;
  border: none;
  background: none;
}

p {
  margin: 0;
}

input, input:focus, input:focus-visible, input:hover {
  border: none;
  outline: none;
}

.selectorLabel {
  display: flex;
  justify-content: space-between;
  gap: 0.5em;

  .checkbox {
    width: 1em;
    height: 1em;
    flex: none;
    order: 0;
    flex-grow: 0;
  }

  > p {
    @include labels-medium();
    color: $neutral-800;
    opacity: 0.8;
    flex: none;
    order: 1;
    flex-grow: 0;
  }
}

.page .divider {
  border: 1px dashed #000000;
  width: 100%;
}