
// Typographies
@mixin medium-medium-bold{
  font-family: "Inter";
  font-weight: 700;
  font-size: 1.25em;
  line-height: 140%;
}
@mixin inter-16-bold {
  font-family: "Inter";
  font-size: 1em;
  font-weight: 700;
  line-height: 1.375em;
}
@mixin labels-regular {
  font-family: "Inter";
  font-size: 1em;
  font-weight: 400;
  line-height: 1.375em;
  line-height: 140%;
}
@mixin labels-bold {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 1em;
  line-height: 140%;
}
@mixin labels-medium {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1em;
  line-height: 140%;
}
@mixin large-bold {
  font-family: "Inter";
  font-size: 1.5em;
  font-weight: 600;
  line-height: 140%;
}
@mixin large-medium {
  font-family: "Inter";
  font-size: 1.5em;
  font-weight: 500;
  line-height: 140%;
}
@mixin small-bold {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 1.125em;
}
@mixin medium-bold {
  font-family: "Inter";
  font-size: 1.25em;
  font-weight: 600;
  line-height: 1.5em;
}
@mixin medium-medium {
  font-family: "Inter";
  font-size: 1.25em;
  font-weight: 500;
  line-height: 140%;
}
@mixin medium-regular {
  font-family: "Inter";
  font-size: 1.25em;
  font-weight: 400;
  line-height: 1.75em;
}
@mixin small-regular {
  font-family: "Inter";
  font-size: 1.125em;
  font-weight: 400;
  line-height: 160%;
}
@mixin x-large-bold {
  font-family: "Inter";
  font-size: 2em;
  font-weight: 600;
  line-height: 140%;
}
@mixin x-small-medium {
  font-family: "Inter";
  font-size: 0.875em;
  font-weight: 500;
  line-height: 160%;
}
@mixin x-small-regular {
  font-family: "Inter";
  font-size: 0.875em;
  font-weight: 400;
  line-height: 1.375em;
}
@mixin x-small-bold {
  font-family: "Inter";
  font-size: 0.875em;
  font-weight: 600;
  line-height: 1.375em;
}
@mixin xx-small-medium {
  font-family: "Inter";
  font-size: 0.75em;
  font-weight: 500;
  line-height: 19px;
}
@mixin xx-large-normal {
  font-family: "Inter";
  font-weight: 500;
  font-size: 2.5em;
  line-height: 140%;
}
@mixin xx-large-bold {
  font-family: "Inter";
  font-weight: 600;
  font-size: 2.5em;
  line-height: 140%;
}

@mixin header-links {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
}