@import "../../../Common";

.content {
  .hot_news.news-events {
    .row {
      justify-content: center;

      .highlight.item {
        max-width: 100% !important;
        width: 100%;
        background: #ffa800;

        .info {
          padding: 1em;
          @include small-regular();

          @media (max-width: 1024px) {
            @include x-small-regular();
          }

          .content {
            margin: 1em;
            text-align: justify;
            text-justify: inter-word;

            a {
              color: gray;
              text-decoration: underline;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
