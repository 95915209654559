@import "../Common";

.homepage {
  > .header {
    width: 100%;

    > .header {
      margin: 0.5em 0em;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @media (max-width: 1024px) {
        margin: 0.5em;
      }

      > .upperSection {
        display: flex;
        padding: 1em 0;
        gap: 0.6em;
        order: 0;
        align-self: stretch;
        flex-grow: 0;
        justify-content: space-between;
        flex-direction: row;
        align-items: flex-start;
        margin: 0 3em;

        @media (max-width: 1024px) {
          align-items: flex-start;
          justify-content: space-between;
          margin: 0;
          padding: 0;
        }

        > .marchioBraille {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          order: 0;

          .largeLogo {
            width: 8em;
          }

          @media (max-width: 1024px) {
            .largeLogo {
              display: none;
            }
          }

          @media (min-width: 1024px) {
            .smallLogo {
              display: none;
            }
          }
        }

        > .largeMenuNavigations {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: center;
          align-items: stretch;
          padding: 0px;
          gap: 2em;
          flex-grow: 0;
          justify-items: stretch;

          @media (max-width: 1024px) {
            display: none;
          }

          > .items {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: flex-start;
            padding: 0px;
            gap: 1em;
            order: 0;
            flex-grow: 0;

            @media (max-width: 1024px) {
              flex-direction: column;
            }

            > div {
              .MuiButton-root {
                color: $black;
                background-color: $white;
                text-transform: none;
              }
            }

            > .buttons {
              display: flex;
              flex-direction: column;
              position: relative;

              > .up {
                display: flex;
                flex-direction: row;
                padding: 0.5em 0.75em;
                gap: 0.5em;

                > .headerItem {
                  box-sizing: border-box;
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                  align-items: center;
                  padding: 0.5em 0.75em;
                  gap: 0.5em;
                  order: 0;
                  flex-grow: 0;

                  > p {
                    @include header-links();
                    display: flex;
                    align-items: center;
                    letter-spacing: -0.01em;
                    color: $neutral-700;
                    order: 0;
                    flex-grow: 0;
                    text-align: center;
                  }

                  > .icon {
                    width: 1em;
                    height: 1em;
                  }
                }

                > .clickable:focus, .clickable:hover {
                  background: $neutral-50;
                  border: 1px solid $neutral-500;
                  border-radius: 0.5em;
                }
              }

              > .down {
                position: absolute;
                top: 3em;
                right: 1em;
                z-index: 1000;
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                border: 1px solid $neutral-200;
                filter: drop-shadow(0px 2px 4px rgba(17, 24, 39, 0.06)) drop-shadow(0px 4px 6px rgba(17, 24, 39, 0.1));
                border-radius: 0.5em;
                flex: none;
                order: 0;
                flex-grow: 0;

                > .item {
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  padding: 0;
                  flex: none;
                  order: 0;
                  align-self: stretch;
                  flex-grow: 0;

                  > .padding {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    padding: 1em;
                    gap: 0.25em;
                    background: $neutral-0;
                    flex: none;
                    order: 0;
                    align-self: stretch;
                    flex-grow: 0;

                    > p {
                      @include labels-bold();
                      color: $neutral-700;
                      flex: none;
                      order: 0;
                      align-self: stretch;
                      flex-grow: 0;
                    }
                  }

                  > .divider {
                    width: 100%;
                    height: 1px;
                    background: $neutral-200;
                    flex: none;
                    order: 1;
                    align-self: stretch;
                    flex-grow: 0;
                  }
                }
              }
            }
          }
        }

        > .rightSection {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          padding: 0px;
          gap: 1.2em;
          flex: none;
          order: 1;
          flex-grow: 0;
          align-self: stretch;

          > div {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            padding: 0px;
            flex: none;
            order: 0;
            flex-grow: 0;
            position: relative;

            > .up {
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              padding: 0.5em 0.75em;
              gap: 0.5em;
              border-radius: 0.5em;
              flex: none;
              order: 0;
              flex-grow: 0;

              > .headerItem.search {
                img {
                  width: 24px;
                  height: 24px;
                }

                @media (max-width: 1024px) {
                  //display: none;
                }
              }

              > .openMenu {
                @media (max-width: 1024px) {
                  img {
                    width: 24px;
                    height: 24px;
                  }
                }

                @media (min-width: 1024px) {
                  display: none;
                }
              }

              > .closeMenu {
                @media (max-width: 1024px) {
                  img {
                    width: 24px;
                    height: 24px;
                  }
                }

                @media (min-width: 1024px) {
                  display: none;
                }
              }

              > div {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 0.5em 0.75em;
                gap: 0.5em;
                border-radius: 0.5em;
                flex: none;
                order: 0;
                flex-grow: 0;

                > .headerItem {
                  box-sizing: border-box;
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                  align-items: center;
                  padding: 0.5em 0.75em;
                  gap: 0.5em;
                  flex: none;
                  order: 0;
                  flex-grow: 0;

                  > .label {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 1em;
                    line-height: 140%;

                    display: flex;
                    align-items: center;
                    letter-spacing: -0.01em;
                    color: $neutral-700;
                    flex: none;
                    order: 1;
                    flex-grow: 0;
                  }
                }
              }
            }

            > .down {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              padding: 0;
              gap: 0.5em;
              position: absolute;
              right: 3em;
              top: 3em;
              background: $neutral-0;
              z-index: 1000;

              > .list {
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding: 0;
                border: 1px solid $neutral-200;
                /* Tailwind/Shadow/M */
                filter: drop-shadow(0px 2px 4px rgba(17, 24, 39, 0.06)) drop-shadow(0px 4px 6px rgba(17, 24, 39, 0.1));
                border-radius: 0.5em;
                flex: none;
                order: 0;
                flex-grow: 0;
                position: absolute;
                right: 0em;
                top: 0.5em;

                > .item {
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  padding: 0em;
                  flex: none;
                  order: 0;
                  align-self: stretch;
                  flex-grow: 0;

                  > .padding {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    padding: 1em;
                    gap: 0.24em;
                    background: $neutral-0;
                    flex: none;
                    order: 0;
                    align-self: stretch;
                    flex-grow: 0;

                    > p {
                      @include header-links();
                      display: flex;
                      align-items: center;
                      letter-spacing: -0.01em;
                      color: $neutral-700;
                      flex: none;
                      order: 0;
                      flex-grow: 0;
                    }
                  }

                  > .divider {
                    width: 100%;
                    height: 1px;
                    background: $neutral-200;
                    flex: none;
                    order: 1;
                    align-self: stretch;
                    flex-grow: 0;
                  }
                }
              }
            }
          }
        }
      }

      > .bottomSection {
        width: 100%;
        height: 1px;
        background: $neutral-200;
        flex: none;
        order: 1;
        align-self: stretch;
        flex-grow: 0;
      }
    }

    > .smallMenuNavigations {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 1em 0;
      gap: 2em;
      flex: none;
      order: 1;
      flex-grow: 0;
      margin: 0.5em;
      margin-top: -1em;

      @media (min-width: 1024px) {
        display: none;
      }

      > .items {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        padding: 0px;
        gap: 1em;
        flex: none;
        order: 0;
        flex-grow: 0;
        align-items: flex-end;

        > div {
          .MuiButton-root {
            color: $black;
            background-color: $white;
            text-transform: none;
            text-align: right;
          }
        }

        @media (max-width: 1024px) {
          flex-direction: column;
          width: calc(100% - 2em);
          margin: 0em 1em;
        }

        > .buttons {
          display: flex;
          flex-direction: column;
          flex-grow: 0;

          > .up {
            display: flex;
            flex-direction: row;
            padding: 0.5em 0.75em;
            gap: 0.5em;
            flex-grow: 0;

            @media (max-width: 1024px) {
              justify-content: space-between;
            }

            > .headerItem {
              box-sizing: border-box;
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              padding: 0.5em 0.75em;
              gap: 0.5em;
              order: 0;
              flex-grow: 0;

              > p {
                @include header-links();
                display: flex;
                align-items: center;
                letter-spacing: -0.01em;
                color: $neutral-700;
                order: 0;
                flex-grow: 0;
              }
            }

            .icon {
              width: 1em;
              height: 1em;
            }

            > .clickable:focus, .clickable:hover {
              background: $neutral-50;
              border: 1px solid $neutral-500;
              border-radius: 0.5em;
            }
          }

          > .down {
            top: 3em;
            right: 1em;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            flex: none;
            order: 0;
            flex-grow: 0;
            padding-left: 0.5em;

            > .item {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              padding: 0;
              flex: none;
              order: 0;
              align-self: stretch;
              flex-grow: 0;

              > .padding {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding: 1em;
                gap: 0.25em;
                background: $neutral-0;
                flex: none;
                order: 0;
                align-self: stretch;
                flex-grow: 0;

                > p {
                  @include labels-regular();
                  color: $neutral-700;
                  flex: none;
                  order: 0;
                  align-self: stretch;
                  flex-grow: 0;
                }
              }

              > .divider {
                width: 100%;
                height: 1px;
                background: $neutral-200;
                flex: none;
                order: 1;
                align-self: stretch;
                flex-grow: 0;
              }
            }
          }
        }
      }
    }
  }
}

.header {
  .lockIcon {
    width: 2em;
    height: 2em;
  }

  .navigations {
    .buttons {
      &:not(:last-of-type) {
        margin-right: 1em;
      }
    }
  }
}

.MuiAccordion-root.Mui-expanded {
  margin: 0 !important;
  z-index: 1000;
}

.MuiMenu-list {
  background-color: rgb(255, 255, 255);
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  overflow-x: auto;
  border: 1px solid $neutral-200;
  border-radius: 0.5em;


  > .MuiMenuItem-root {
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.43;
    text-align: left;
    padding: 16px;
    color: rgba(0, 0, 0, 0.87);
  }
}

h2 {
  @include medium-regular();

  @media (max-width: 1024px) {
    text-align: center;
    margin: 0;
  }
}
