@import "../../Common";

.newsDetail {
  > .section {
    > .content {
      > .image {
        margin-top: 3em;
        /*width: 100%;*/
      }

      img {
        max-width: 100%;
        max-height: 30em;
      }

      > .left-side {
        width: 100% !important;
      }

      > .right-side {
        > .agenda {
          @include small-bold();
        }

        > .divider {
          margin-bottom: 2em;
          margin-top: 2em;
        }

        > .detail {
          display: flex;
          flex-direction: row;
          gap: 2em;
          justify-content: space-around;

          > div {
            > .title {
              @include x-small-bold();
              text-transform: uppercase;
              margin-bottom: 1em;
            }

            > .subTitle {
              @include x-small-bold();
            }

            > .value {
              @include x-small-regular();
              margin-bottom: 1em;
            }
          }
        }
      }
    }
  }
}