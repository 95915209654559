@import "../../Common";

.consortium {
  > .section:last-child {
    > .content > .right-side > .divider {
      display: none;
    }
  }

  > .section > .content {
    > .left-side > .image {
      width: 265px;
    }

    > .right-side {
      > .consortium > .title {
        @include labels-medium();
        margin-bottom: 1em;
      }

      > .divider {
        margin-top: 5em;
      }
    }
  }
}