@import "src/assets/scss/Common";

.searchOverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;

  > .container {
    width: 100%;

    > .searchModal {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0;
      position: relative;
      width: 50%;
      top: 5em;
      border: 1px solid $neutral-200;
      border-radius: 0.5em;
      z-index: 1000;
      margin: 0 auto;

      > .searchBox {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0.75em 0.75em 0.75em 1.5em;
        gap: 1em;
        height: 4em;
        background: $neutral-0;
        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;

        > .container {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0;
          gap: 0.75em;
          width: 100%;
          height: 2em;
          flex: none;
          order: 0;
          flex-grow: 1;

          > .frame {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0;
            gap: 0.5em;
            width: auto;
            height: 1.5em;
            flex: none;
            order: 0;
            flex-grow: 1;

            > .searchText {
              @include labels-regular;
              display: flex;
              align-items: center;
              color: $neutral-700;
              flex: none;
              order: 0;
              flex-grow: 0;
            }

            > .button {
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              padding: 0.5em;
              gap: 0.5em;
              width: 28px;
              height: 28px;
              background: $neutral-100;
              border-radius: 0.5em;
              flex: none;
              order: 1;
              flex-grow: 0;
            }

            > .label {
              @include labels-regular;
              display: flex;
              align-items: center;
              color: $neutral-500;
              flex: none;
              order: 2;
              flex-grow: 0;
            }
          }

          > .button {
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 0.5em 0.75em;
            gap: 0.5em;
            width: 79px;
            height: 38px;
            background: $neutral-0;
            border: 1px solid $neutral-200;
            border-radius: 0.5em;
            flex: none;
            order: 1;
            flex-grow: 0;

            > .label {
              @include labels-bold;
              display: flex;
              align-items: center;
              color: $neutral-700;
              flex: none;
              order: 0;
              flex-grow: 0;
            }
          }
        }
      }

      > .divider {
        width: 100%;
        height: 1px;
        background: $neutral-200;
        flex: none;
        order: 1;
        align-self: stretch;
        flex-grow: 0;
      }

      > .searchResults {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 1.5em 1.5em 2em;
        gap: 1em;
        height: auto;
        background: $neutral-0;
        flex: none;
        order: 2;
        align-self: stretch;
        flex-grow: 0;

        > .container {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 0;
          gap: 1em;
          flex: none;
          order: 0;
          flex-grow: 1;

          > .title {
            width: 43em;
            height: 1.5em;
            @include labels-medium;
            display: flex;
            align-items: center;
            color: $neutral-500;
            flex: none;
            order: 0;
            align-self: stretch;
            flex-grow: 0;
          }

          > .categoriesList {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0;
            gap: 1em;
            flex: none;
            order: 1;
            align-self: stretch;
            flex-grow: 0;

            > .clickable {
              padding: 0em;

              > .item {
                @include labels-bold;
                color: $accent-base;
                flex: none;
                order: 0;
                align-self: stretch;
                flex-grow: 1;
                margin: 0;
              }
            }
          }

          > .allResults {
            @include labels-bold;
            display: flex;
            align-items: center;
            color: $accent-base;
            flex: none;
            order: 2;
            flex-grow: 0;
          }
        }
      }

      > .quickResults {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 1.5em 1.5em 2em;
        gap: 1em;
        background: $neutral-0;
        flex: none;
        order: 2;
        align-self: stretch;
        flex-grow: 0;

        > .container {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 0;
          gap: 1em;
          flex: none;
          order: 0;
          flex-grow: 1;
          max-width: 100%;

          > .title {
            height: 1.5em;
            @include labels-medium;
            display: flex;
            align-items: center;
            color: $neutral-500;
            flex: none;
            order: 0;
            align-self: stretch;
            flex-grow: 0;
          }

          > .storiesList {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0;
            gap: 1.5em;
            flex: none;
            order: 1;
            align-self: stretch;
            flex-grow: 0;

            > .clickable {
              padding: 0em;

              > .item {
                @include labels-bold;
                color: $accent-base;
                flex: none;
                order: 0;
                align-self: stretch;
                flex-grow: 1;
                margin: 0;
              }
            }

            > .storyCard {
              display: flex;
              flex-direction: row;
              align-items: center;
              padding: 0;
              flex: none;
              order: 0;
              flex-grow: 0;

              > img {
                width: 60px;
                height: 60px;
                border-radius: 4px;
                flex: none;
                order: 0;
                flex-grow: 0;
              }

              > .textContainer {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                padding: 0 1em;
                gap: 0.5em;
                flex: none;
                order: 1;
                flex-grow: 0;
                max-width: 100%;

                > .frame {
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  padding: 0;
                  flex: none;
                  order: 0;
                  flex-grow: 0;

                  > p {
                    height: 1.5em;
                    @include labels-bold();
                    color: $neutral-700;
                    flex: none;
                    order: 0;
                    flex-grow: 0;
                    overflow-wrap: break-word;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  > .background {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: $neutral-700;
    opacity: 0.24;
  }
}