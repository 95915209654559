@import "colors";

.homepage {
  width: 100%;
  background-color: $white;
  overflow: hidden;
  padding: 7px 0 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
}