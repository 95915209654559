@import "../../Common";

.dashboard {
  width: 100%;

  > .braille {
    display: flex;
    margin: 5% 0px;

    @media (max-width: 1024px) {
      display: none;
    }

    > .first {
      margin: auto;
    }

    > .second {
      margin: auto;
    }
  }

  > .section {
    > .title {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      padding: 0px;

      @media (max-width: 1024px) {
        gap: 1em;
      }

      > p {
        @include xx-large-bold();
        color: $black;
        margin-bottom: 1em;

        @media (max-width: 1024px) {
          margin-bottom: 0.5em;
        }
      }
    }

    > .content {
      > .demo {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        > button {
          margin: 0.5em;
          width: 15em;
        }

        @media (max-width: 1024px) {
          display: none;
        }
      }

      > .homepage {
        background-color: $white;
        overflow: hidden;
        padding: 1em 0 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: relative;
      }

      > .highlights {
        > .container {
          > .row {
            display: flex;
            flex-direction: row;
            align-self: stretch;
            padding: 0px;

            @media (max-width: 1024px) {
              flex-direction: column;
            }

            > .item {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              align-self: stretch;
              padding: 0px;

              > .highlightImages {
                height: auto;
                align-self: stretch;
                margin-bottom: 1em;
                object-fit: cover;

                @media (max-width: 1024px) {
                  width: 100%;
                }
              }

              > img {
                flex: none;
                order: 0;
                align-self: stretch;
                flex-grow: 1;
                object-fit: contain;
              }

              > .info {
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                padding: 0.6em 1.5em;
                gap: 0.6em;
                flex: none;
                order: 1;
                align-self: stretch;
                flex-grow: 0;

                > p {
                  color: $black;
                  flex: 1;
                  @include large-bold;
                  order: 0;
                  flex-grow: 1;
                }
              }
            }
          }
        }
      }

      > .details {
        position: relative;
        width: 70%;

        @media (max-width: 1024px) {
          width: 100%;
        }

        .circle1 {
          border-radius: 50%;
          position: absolute;
          width: 15em;
          height: 15em;
          left: -20em;

          background: #0B9D3A;

          @media (max-width: 1024px) {
            display: none;
          }
        }

        .circle2 {
          width: 8em;
          height: 8em;
          background-color: $cyan;
          border-radius: 50%;
          position: absolute;
          left: -5em;
          bottom: 100em;

          @media (max-width: 1024px) {
            display: none;
          }
        }

        > .item {
          position: relative;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-start;
          padding: 0px;
          gap: 8em;
          margin: 0px auto;
          margin-bottom: 5em;

          @media (max-width: 1024px) {
            flex-direction: column;
            gap: 0;
            margin: 1em;
          }

          > .left-side {
            color: $black;
            display: flex;
            @include x-large-bold;
            width: 15%;

            @media (max-width: 1024px) {
              width: 100%;
            }
          }

          > .right-side {
            width: 75%;
            color: $black;
            text-align: justify;
            @include small-regular;
            white-space: pre-line;

            @media (max-width: 1024px) {
              width: 100%;
            }
          }
        }

        > .subDetail {
          margin: 0em 5em;
          display: flex;
          flex-direction: column;
          position: relative;
          gap: 5em;

          @media (max-width: 1024px) {
            gap: 1em;
            margin: 0em 1em;
          }

          > .row {
            padding: 0 2px 0 0;
            display: flex;
            align-items: flex-start;
            justify-content: space-evenly;
            gap: 5em;

            @media (max-width: 1024px) {
              flex-direction: column;
              gap: 1em;
            }

            > .item {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              position: relative;
              width: 100%;

              @media (max-width: 1024px) {
                width: auto;
              }

              > .card {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                > .up {
                  margin-bottom: 2em;
                  padding: 3em;
                  display: flex;
                  align-items: flex-start;
                  align-self: stretch;
                  justify-content: center;

                  @media (max-width: 1024px) {
                    margin-bottom: 1em;
                    padding: 2em;
                  }

                  > img {
                    z-index: 1000;
                  }

                  > .image {
                    > .circle1 {
                      width: 179px;
                      height: 179px;
                      background-color: #018FA3;
                      border-radius: 50%;
                      position: absolute;
                      left: 7px;
                      top: 44px;
                    }

                    > .circle2 {
                      width: 201px;
                      height: 201px;
                      background-color: #FF37C3;
                      border-radius: 50%;
                      position: absolute;
                      left: 5px;
                      top: 67px;
                    }

                    > .circle3 {
                      width: 200px;
                      height: 200px;
                      background-color: #0BE495;
                      border-radius: 50%;
                      position: absolute;
                      right: 2px;
                      top: 14px;
                    }

                    > .circle4 {
                      width: 163px;
                      height: 163px;
                      background-color: #917C00;
                      border-radius: 50%;
                      position: absolute;
                      left: 48px;
                      top: 66px;
                    }

                    > .circle5 {
                      width: 194px;
                      height: 194px;
                      background-color: #FFA800;
                      border-radius: 50%;
                      position: absolute;
                      left: 13px;
                      top: 9px;
                    }

                    > .circle6 {
                      width: 124px;
                      height: 124px;
                      background-color: #5500A4;
                      border-radius: 50%;
                      position: absolute;
                      right: 184px;
                      top: 8px;
                    }
                  }

                }

                > .down {
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  align-self: stretch;

                  > .title {
                    color: rgb(0, 0, 0);
                    align-self: stretch;
                    margin-bottom: 1.5em;
                    @include medium-bold();
                  }

                  > .text {
                    color: rgb(0, 0, 0);
                    align-self: stretch;
                    text-align: justify;
                    @include x-small-regular();
                  }
                }
              }
            }
          }
        }
      }

      > .consortiums {
        display: flex;
        flex-direction: column;
        gap: 2em;

        > .row {
          display: flex;
          align-items: flex-end;
          gap: 1.5em;

          @media (max-width: 1024px) {
            flex-direction: column;
            align-items: stretch;
          }

          > .item {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-end;
            flex: 1;

            @media (max-width: 1024px) {
              align-items: center;
            }

            > img {
              margin-bottom: 1.5em;
              margin-right: auto;
              margin-left: auto;
            }

            > .info {
              padding: 0.5em 0;
              display: flex;
              align-items: flex-start;
              align-self: stretch;
              border-top: 1px dashed rgb(0, 0, 0);
              min-height: 3em;

              > .name {
                color: rgb(0, 0, 0);
                flex: 1 1;
                @include x-small-medium();
                text-align: center;
              }
            }
          }
        }
      }

      > .news-events {
        display: flex;
        flex-direction: column;
        gap: 5em;

        > .row {
          display: flex;
          align-items: flex-end;
          gap: 1.5em;

          @media (max-width: 1024px) {
            flex-direction: column;
          }

          > .item {
            display: flex;
            flex-direction: column;
            align-items: center;
            flex: 1;
            gap: 1em;
            align-items: flex-start;
            align-self: stretch;
            padding: 0px;

            @media (min-width: 1024px) {
              max-width: calc(50% - 1em);
            }

            > img {
              height: auto;
              object-fit: cover;
              width: 100%;
              height: 100%;
              align-self: stretch;
              -webkit-align-self: center;
              margin: auto;
              overflow: hidden;
            }

            > .info {
              align-self: flex-start;

              > .date {
                color: $neutral-500;
                align-self: stretch;
                margin-bottom: 0.5em;
                @include labels-regular;
              }

              > .title {
                color: $neutral-700;
                align-self: stretch;
                @include large-bold;
              }
            }
          }
        }
      }
    }
  }
}