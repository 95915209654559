@import "../../Common";

.partners {
  > .section:not(:first-child) {
    > .content > .left-side > .title p {
      display: none;
    }
  }

  > .section:last-child {
    > .content > .right-side > .divider {
      display: none;
    }
  }

  > .section > .content {
    > .left-side {
      display: flex;
      justify-content: center;

      > .image {
        max-width: 100%;
        max-height: 300px;
      }
    }

    > .right-side {
      > .consortium > .title {
        @include labels-medium();
        margin-bottom: 1em;
      }

      > .divider {
        margin-top: 5em;
      }
    }
  }
}