@import "../../Common";

.page.style1 {
  width: 100%;
  margin-top: 5em;
  margin-bottom: 10em;

  @media (max-width: 1024px) {
    margin-top: 1em;
  }

  > .title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    margin: 0em 10em 2em 10em;
    text-align: center;

    @media (max-width: 1024px) {
      margin: 0em 1em 2em 1em;
      align-items: center;
      text-align: center;
    }

    > .title {
      @include x-large-bold();

      @media (max-width: 1024px) {
        @include large-bold();
      }
    }

    > .subTitle {
      @include medium-medium();
    }
  }

  > .content {
    > .section {
      margin: 0em auto 5em auto;
      width: 70%;

      @media (max-width: 1024px) {
        margin: 1em;
        width: unset;
      }

      > .content {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        gap: 4em;
        flex-grow: 0;

        @media (max-width: 1024px) {
          flex-direction: column;
          gap: 1em;
        }

        > .image {
          width: 100%;
        }

        > .left-side {
          width: 20%;
          white-space: pre-line;
          @include small-regular();
          text-align: justify;

          @media (max-width: 1024px) {
            width: auto;
            align-self: center;
            @include x-small-regular();
          }

          > .title {
            color: $black;
            display: flex;
            @include large-bold;
            width: 100%;
          }
        }

        > .right-side {
          width: 70%;
          color: $black;
          text-align: justify;
          @include small-regular;
          white-space: pre-line;

          @media (max-width: 1024px) {
            width: 100%;
            @include x-small-regular();
          }
        }
      }

      > .content.centered {
        justify-content: center;
        align-items: center;
      }
    }

    > .section.fullWidth {
      margin: 0em 0em 5em 0em;
      width: unset;

      @media (max-width: 1024px) {
        margin: 0em 0em 0.5em 0em;
      }

      > .title {
        margin-left: 10em;

        @media (max-width: 1024px) {
          margin: 1em;
        }
      }
    }
  }
}

.page.style2 {
  width: 100%;

  > .content {
    margin: 0em 10em 0em 10em;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    padding: 5em 0;

    @media (max-width: 1024px) {
      margin: 1em;
    }

    > .row {
      margin: auto;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      position: relative;
      gap: 1em;
      align-self: center;

      @media (max-width: 1024px) {
        flex-direction: column;
        gap: 3em;
      }
    }
  }
}

.pageTitle {
  background-color: $neutral-50;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid $lavender;

  > .containerTwo {
    padding: 64px 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;

    > .container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      > .title {
        color: $neutral-700;
        align-self: stretch;
        text-align: center;
        margin-bottom: 16px;
        @include x-large-bold;
      }

      > .description {
        color: $neutral-500;
        align-self: stretch;
        text-align: center;
        @include medium-regular;
      }
    }
  }
}

.page.style3 {
  width: calc(100% - 20em);
  display: flex;
  flex-direction: column;
  margin: 0em 10em 0em 10em;

  > .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    @media (max-width: 1024px) {
      margin: 1em;
    }

    > .row {
      margin: auto;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      position: relative;
      gap: 1.5em;
      align-self: center;
      margin: 3em;
      width: 100%;

      @media (max-width: 1024px) {
        flex-direction: column;
        gap: 3em;
      }
    }
  }
}

.MuiFormLabel-root, .MuiTableCell-root > textarea {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
}

.page.mooc {
  width: 90%;

  @media (max-width: 1024px) {
    width: 100%;
  }

  .MuiFormLabel-root, .MuiTableCell-root > textarea {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 0.85rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    border-radius: 4px;
    color: rgba(0, 0, 0, 0.87);
    box-sizing: border-box;
  }

  .MuiTabs-root {
    .MuiButtonBase-root {
      min-width: 0;
    }
  }


  .MuiFormLabel-root {
    padding: 0;
  }

  .audioPlayer {
    display: flex;
    margin: 1em;
    width: 50%;
    align-self: center;
    @include xx-small-medium();

    @media (max-width: 1024px) {
      justify-content: center;
    }

    .rhap_time {
      font-size: 1em !important;
    }
  }

  .react-pdf__Document {
    width: 100% !important;
    height: 100% !important;
    overflow: scroll !important;
    border: 1px solid;
  }

  .react-pdf__Page__canvas {
    margin: 0 auto;
  }

  .pdfViewer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    align-items: center;

    .pagination {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .zoom {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 1em;
      margin: 1em;
    }

    .progressBar {
      width: 100%;
      display: flex;
      justify-content: center;
      margin: 2em;
    }
  }

  > .title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    margin: 0em 1em 2em 1em;
    text-align: center;

    @media (max-width: 1024px) {
      align-items: center;
    }

    > .title {
      @include large-bold();
    }

    > .subTitle {
      @include medium-medium();
    }
  }

  > .content {
    > .section {
      margin: 1em;
      width: unset;

      > .title {
        @include medium-medium();
      }

      > .content {
        @include x-small-regular();
        white-space: pre-line;
        text-align: justify;

        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        flex-grow: 0;

        @media (max-width: 1024px) {
          flex-direction: column;
          gap: 1em;
        }

        > .image {
          width: 100%;
        }

        > .left-side {
          width: 20%;
          white-space: pre-line;
          @include small-regular();
          text-align: justify;

          @media (max-width: 1024px) {
            width: auto;
            align-self: center;
            @include x-small-regular();
          }

          > .title {
            color: $black;
            display: flex;
            @include large-bold;
            width: 100%;
          }
        }

        > .right-side {
          width: 70%;
          color: $black;
          text-align: justify;
          @include small-regular;
          white-space: pre-line;

          @media (max-width: 1024px) {
            width: 100%;
            @include x-small-regular();
          }
        }
      }

      > .content.centered {
        justify-content: center;
        align-items: center;
      }
    }

    > .section.fullWidth {
      margin: 0em 0em 5em 0em;
      width: unset;

      @media (max-width: 1024px) {
        margin: 0em 0em 0.5em 0em;
      }

      > .title {
        margin-left: 10em;

        @media (max-width: 1024px) {
          margin: 1em;
        }
      }
    }
  }
}
