@import "../../../Common";

.page.style1 {
  width: 100%;
  margin-top: 5em;
  margin-bottom: 5em;

  @media (max-width: 1024px) {
    margin-top: 1em;
  }

  > .title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    margin: 0em 10em 2em 10em;

    @media (max-width: 1024px) {
      margin: 0em 1em 2em 1em;
      align-items: center;
      text-align: center;
    }

    > .title {
      @include xx-large-bold();

      @media (max-width: 1024px) {
        @include large-bold();
      }
    }

    > .subTitle {
      @include medium-medium();
    }
  }

  > .content {
    > .section {
      margin: 0em auto 5em auto;
      width: 70%;

      @media (max-width: 1024px) {
        margin: 1em;
        width: unset;
        margin-top: 0;
      }

      > .content {
        max-width: 100%;
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        gap: 4em;
        flex-grow: 0;

        @media (max-width: 1024px) {
          flex-direction: column;
          gap: 1em;
        }

        > .image {
          width: 100%;
        }

        > .left-side {
          width: 20%;
          white-space: pre-line;
          @include small-regular();
          text-align: justify;

          @media (max-width: 1024px) {
            width: auto;
            align-self: center;
            @include x-small-regular();
          }

          > .title {
            color: $black;
            display: flex;
            @include x-large-bold;
            width: 100%;

            @media (max-width: 1024px) {
              justify-content: center;
              text-align: center;
            }
          }
        }

        > .right-side {
          width: 70%;
          color: $black;
          text-align: justify;
          @include small-regular();
          white-space: pre-line;

          @media (max-width: 1024px) {
            width: 100%;
            @include x-small-regular();
          }
        }
      }

      > .content.centered {
        justify-content: center;
        align-items: center;
      }
    }

    > .section.fullWidth {
      margin: 0em 0em 5em 0em;
      width: unset;

      @media (max-width: 1024px) {
        margin: 0em 0em 0.5em 0em;
      }

      > .title {
        margin-left: 10em;

        @media (max-width: 1024px) {
          margin: 1em;
        }
      }
    }
  }
}

.page.style2 {
  width: 100%;

  > .content {
    margin: 0em 10em 0em 10em;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    padding: 5em 0;

    @media (max-width: 1024px) {
      margin: 1em;
    }

    > .row {
      margin: auto;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      position: relative;
      gap: 1em;
      align-self: center;

      @media (max-width: 1024px) {
        flex-direction: column;
        gap: 3em;
      }
    }
  }
}

.pageTitle {
  background-color: $neutral-50;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid $lavender;

  > .containerTwo {
    padding: 64px 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;

    > .container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      > .title {
        color: $neutral-700;
        align-self: stretch;
        text-align: center;
        margin-bottom: 16px;
        @include x-large-bold;
      }

      > .description {
        color: $neutral-500;
        align-self: stretch;
        text-align: center;
        @include medium-regular;
      }
    }
  }
}

.page.style3 {
  width: calc(100% - 20em);
  display: flex;
  flex-direction: column;
  margin: 0em 10em 0em 10em;

  > .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    @media (max-width: 1024px) {
      margin: 1em;
    }

    > .row {
      margin: auto;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      position: relative;
      gap: 1.5em;
      align-self: center;
      margin: 3em;
      width: 100%;

      @media (max-width: 1024px) {
        flex-direction: column;
        gap: 3em;
      }
    }
  }
}

.MuiFormLabel-root, .MuiTableCell-root > textarea {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
}
