@import "../../Common";

.page .content.search .row {
  align-self: stretch;
}

.search {
  .filters {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    gap: 1.5em;

    > .search {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 0;
      gap: 0.5em;
      flex: none;
      order: 0;
      align-self: stretch;
      flex-grow: 0;

      > .input {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 0;
        gap: 0.25em;
        flex: none;
        order: 0;
        flex-grow: 1;

        > .input {
          box-sizing: border-box;
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0.75em 1em;
          gap: 0.5em;
          border: 1px solid $neutral-200;
          border-radius: 0.5em;
          flex: none;
          order: 0;
          align-self: stretch;
          flex-grow: 0;

          > .label {
            @include labels-bold();
            display: flex;
            align-items: center;
            color: $neutral-700;
            flex: none;
            order: 0;
          }

          > .icon {
            width: 1em;
            height: 1em;
            flex: none;
            order: 1;
            flex-grow: 0;
          }
        }
      }
    }

    > .filterList {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 2em 0;
      gap: 1.5em;
      background: $white;
      border: 1px solid $neutral-200;
      border-radius: 0.5em;
      flex: none;
      order: 1;
      align-self: stretch;
      flex-grow: 0;

      > .storyType {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0 1.5em;
        gap: 1em;
        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;

        > .title {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0;
          gap: 1em;
          flex: none;
          order: 0;
          align-self: stretch;
          flex-grow: 0;

          > .storyType {
            width: 170px;
            @include labels-bold();
            color: $neutral-700;
            flex: none;
            order: 0;
            flex-grow: 1;
          }

          > .icon {
            width: 1em;
            height: 1em;
            flex: none;
            order: 1;
            flex-grow: 0;
          }
        }

        > .items {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 0;
          gap: 1em;
          width: 202px;
          height: 60px;
          flex: none;
          order: 1;
          align-self: stretch;
          flex-grow: 0;

          > .selectorLabel {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0;
            gap: 0.75em;
            flex: none;
            order: 0;
            flex-grow: 0;

            > .label {
              @include labels-medium();
              color: $neutral-700;
              opacity: 0.8;
              flex: none;
              order: 1;
              flex-grow: 0;
            }
          }
        }
      }

      > .divider {
        width: 100%;
        height: 1px;
        background: $neutral-200;
        flex: none;
        order: 1;
        align-self: stretch;
        flex-grow: 0;
      }

      > .categories {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0 1.5em;
        gap: 1em;
        flex: none;
        order: 2;
        align-self: stretch;
        flex-grow: 0;

        > .title {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0;
          gap: 1em;
          flex: none;
          order: 0;
          align-self: stretch;
          flex-grow: 0;

          > .categories {
            @include labels-bold();
            color: $neutral-700;
            flex: none;
            order: 0;
            flex-grow: 1;
          }
        }

        > .items {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 0;
          gap: 1em;
          flex: none;
          order: 1;
          align-self: stretch;
          flex-grow: 0;

          > .selectorLabel {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0;
            gap: 0.75em;
            flex: none;
            order: 0;
            flex-grow: 0;

            > .label {
              @include labels-medium();
              color: $neutral-700;
              opacity: 0.8;
              flex: none;
              order: 1;
              flex-grow: 0;
            }
          }

          > .viewMore {
            @include labels-bold();
            display: flex;
            align-items: center;
            color: $accent-base;
            flex: none;
            order: 2;
            flex-grow: 0;
          }
        }
      }

      > .years {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0 1.5em;
        gap: 1em;
        flex: none;
        order: 2;
        align-self: stretch;
        flex-grow: 0;

        > .title {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0;
          gap: 1em;
          flex: none;
          order: 0;
          align-self: stretch;
          flex-grow: 0;

          > .years {
            @include labels-bold();
            color: $neutral-700;
            flex: none;
            order: 0;
            flex-grow: 1;
          }
        }

        > .items {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 0;
          gap: 1em;
          flex: none;
          order: 1;
          align-self: stretch;
          flex-grow: 0;

          > .selectorLabel {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0;
            gap: 0.75em;
            flex: none;
            order: 0;
            flex-grow: 0;

            > .label {
              @include labels-medium();
              color: $neutral-700;
              opacity: 0.8;
              flex: none;
              order: 1;
              flex-grow: 0;
            }
          }
        }
      }

      > .months {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0 1.5em;
        gap: 1em;
        flex: none;
        order: 2;
        align-self: stretch;
        flex-grow: 0;

        > .title {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0;
          gap: 1em;
          flex: none;
          order: 0;
          align-self: stretch;
          flex-grow: 0;

          > .months {
            @include labels-bold();
            color: $neutral-700;
            flex: none;
            order: 0;
            flex-grow: 1;
          }
        }

        > .items {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 0;
          gap: 1em;
          flex: none;
          order: 1;
          align-self: stretch;
          flex-grow: 0;

          > .selectorLabel {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0;
            gap: 0.75em;
            flex: none;
            order: 0;
            flex-grow: 0;

            > .label {
              @include labels-medium();
              color: $neutral-700;
              opacity: 0.8;
              flex: none;
              order: 1;
              flex-grow: 0;
            }
          }
        }
      }
    }
  }

  .resultList {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    gap: 2em;
    position: relative;
    left: 3em;

    .card {
      display: flex;
      flex-direction: row;
      align-items: stretch;
      padding: 0;
      height: 199px;
      flex: none;
      order: 0;
      align-self: stretch;
      flex-grow: 0;

      .image {
        width: 148px;
        flex: none;
        order: 0;
        flex-grow: 0;
        align-self: center;
      }

      .container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 1.5em;
        gap: 0.75em;
        width: 682px;
        height: 199px;
        background: $neutral-0;
        flex: none;
        order: 1;
        flex-grow: 1;

        .title {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 0;
          gap: 0.5em;
          flex: none;
          order: 0;
          align-self: stretch;
          flex-grow: 0;

          .info {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 0;
            flex: none;
            order: 0;
            align-self: stretch;
            flex-grow: 0;

            .category {
              @include labels-bold();
              color: $accent-base;
              flex: none;
              order: 0;
              flex-grow: 1;
            }
          }

          .title {
            @include small-bold();
            line-height: 160%;
            color: $neutral-700;
            flex: none;
            order: 1;
            align-self: stretch;
            flex-grow: 0;
          }

          .subtitle {
            @include small-regular();
            line-height: 160%;
            color: $neutral-500;
            flex: none;
            order: 2;
            align-self: stretch;
            flex-grow: 0;
            text-align: justify;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
          }
        }

        .date {
          height: 1.5em;
          @include labels-regular();
          color: $neutral-500;
          flex: none;
          order: 1;
          flex-grow: 0;
        }
      }
    }
  }
}