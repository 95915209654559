// This is the project stylesheet

// Colors
$accent-base: #FF8A00;
$black: rgba(0, 0, 0, 1);
$black-4: rgba(35, 31, 32, 1);
$black-5: rgba(31, 31, 31, 1);
$cyan: rgba(24, 233, 233, 1);
$dark-blue: rgba(0, 51, 153, 1);
$dark-orange: rgba(255, 147, 38, 1);
$deep-pink: rgba(252, 11, 127, 1);
$gainsboro: rgba(229, 229, 229, 1);
$gainsboro-2: rgba(230, 230, 230, 1);
$ghost-white: rgba(249, 249, 249, 1);
$green-yellow: rgba(166, 233, 24, 1);
$khaki: rgba(218, 255, 140, 1);
$lavender: rgba(229, 231, 235, 1);
$neutral-0: #FFFFFF;
$neutral-100: #F3F4F6;
$neutral-200: #E5E7EB;
$neutral-300: #D1D5DB;
$neutral-50: #F9FAFB;
$neutral-500: #6B7280;
$neutral-700: #374151;
$neutral-800: rgba(31, 41, 55, 1);
$orange: rgba(255, 168, 0, 1);
$orange-1: rgba(255, 153, 0, 1);
$transparent-black: rgba(0, 0, 0, 0.1);
$transparent-black-2: rgba(0, 0, 0, 0.5);
$transparent-black-3: rgba(0, 0, 0, 0.8);
$transparent-black-4: rgba(0, 0, 0, 0.25);
$white: rgba(255, 255, 255, 1);
$white-smoke: rgba(240, 240, 240, 1);
$yellow: rgba(255, 245, 0, 1);
$base-700: #374151;