@import "../../Common";

.events {
  > .section > .content {
    > .event {
      max-width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1em;
      order: 0;
      flex-grow: 0;
      text-align: center;

      > .image {
        max-width: 100%;
        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;
        overflow: hidden;
        height: 20em;
        width: 80em;

        @media (max-width: 1024px) {
          height: unset;
          width: 100%;
        }

        > img {
          width: 100%;

          @media (min-width: 1024px) {
            transform: translateX(0%) translateX(0%) translateX(0%) translateY(-25%);
          }
        }
      }

      > .info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0;
        gap: 0.5em;
        flex: none;
        order: 1;
        align-self: stretch;
        flex-grow: 0;

        > .date {
          @include labels-regular();
          color: $neutral-500;
          flex: none;
          order: 0;
          align-self: stretch;
          flex-grow: 0;
        }

        > .name {
          @include large-bold();
          color: $neutral-700;
          flex: none;
          order: 1;
          align-self: stretch;
          flex-grow: 0;
        }
      }
    }
  }
}