@import "../../Common";

.eventDetail {
  > .section {
    > .content {
      > .image {
        margin-top: 3em;
        width: 100%;
      }

      > .left-side {
        width: 100% !important;

        > .info {
          @include small-regular();
          text-align: justify;
        }
      }

      > .right-side {
        > .agenda {
          @include small-bold();
        }

        > .presentations {
          margin-top: 1em;
          @include small-bold();
          display: flex;
          flex-direction: column;

          > a {
            @include small-bold();
            padding-left: 1em;

            > span {
              @include x-small-regular();
            }
          }
        }

        > .divider {
          margin-bottom: 2em;
          margin-top: 2em;
        }

        > .detail {
          display: flex;
          flex-direction: row;
          gap: 2em;
          justify-content: space-around;

          > div {
            > .title {
              @include x-small-bold();
              text-transform: uppercase;
              margin-bottom: 1em;
            }

            > .subTitle {
              @include x-small-bold();
            }

            > .value {
              @include x-small-regular();
              margin-bottom: 1em;
            }
          }
        }
      }
    }
  }
}