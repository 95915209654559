@import "../../Common";

.projectResults {
  > .section:not(:first-child) {
    > .content > .left-side > .title p {
      display: none;
    }
  }

  > .section {
    > .content {
      > .image {
        width: 100%;
      }

      > .left-side {
        position: relative;

        > .circle1 {
          border-radius: 50%;
          position: absolute;
          width: 215.11px;
          height: 215.11px;
          left: -3em;
          top: -1em;
          background: #018FA3;

          @media (max-width: 1024px) {
            display: none;
          }
        }

        > .circle2 {
          border-radius: 50%;
          position: absolute;
          width: 223.52px;
          height: 223.52px;
          background: #FF37C3;
          top: 2em;
          left: 2em;

          @media (max-width: 1024px) {
            display: none;
          }
        }

        > .circle3 {
          border-radius: 50%;
          position: absolute;
          width: 215.94px;
          height: 215.94px;
          left: 3em;
          top: -2em;
          background: #0BE495;

          @media (max-width: 1024px) {
            display: none;
          }
        }

        > .circle4 {
          border-radius: 50%;
          position: absolute;
          width: 173.87px;
          height: 173.87px;
          left: -3em;
          top: 3em;
          background: #917C00;

          @media (max-width: 1024px) {
            display: none;
          }
        }

        > .circle5 {
          border-radius: 50%;
          position: absolute;
          width: 231.6px;
          height: 231.6px;
          left: 2em;
          top: -5em;
          background: #FFA800;

          @media (max-width: 1024px) {
            display: none;
          }
        }

        > .circle6 {
          border-radius: 50%;
          position: absolute;
          width: 130.83px;
          height: 130.83px;
          left: -1em;
          top: -2em;
          background: #5500A4;

          @media (max-width: 1024px) {
            display: none;
          }
        }

        > .image {
          z-index: 1000;
          max-width: 100%;

          @media (max-width: 1024px) {
            position: relative;
          }
        }

      }

      > .right-side {
        position: relative;

        > .sub_title {
          @include medium-bold();
        }

        > .title {
          @include large-bold();
          margin-bottom: 1em;
        }

        > .content {
          @include small-regular();
        }

        > .divider {
          margin-top: 5em;
        }
      }
    }
  }
}